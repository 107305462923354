@import "@/styles/index.scss";

.anchor {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    @include fontLabelSmall;
}

.wrapAvatar {
    display: flex;
    align-items: center;
    margin-left: 2rem;

    a>div {
        display: flex;
        align-items: center;
        margin-left: 20px;
    }

    &:hover {
        @include setProperty(opacity, --opacity-value);
    }
}

.name {
    margin-left: 4px;
}

@import "@/styles/index.scss";

.confirm {
    @include setProperty(padding, --modal-confirm-padding);
}

.classNameHeader {}

.ConfirmModal {
    max-width: 400px;
    @include mobileParameter(max-width, unset);

    .header {
        display: flex;
        align-items: center;

        svg {
            margin-right: 12px;

            &.primary {
                @include setProperty(color, --color-primary);
                @include setProperty(fill, --color-primary);
            }

            &.secondary {
                @include setProperty(color, --color-secondary);
                @include setProperty(fill, --color-secondary);
            }

            &.info {
                @include setProperty(color, --color-info);
                @include setProperty(fill, --color-info);
            }

            &.error {
                @include setProperty(color, --color-error);
                @include setProperty(fill, --color-error);
            }

            &.black {
                @include setProperty(color, --color-black);
                @include setProperty(fill, --color-black);
            }

        }
    }

    .title {
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0.15px;
        font-size: 26px;
        text-align: center;
    }

    .message {
        margin: 12px 0;
        line-height: 24px;
        min-height: 60px;
        white-space: break-spaces;
    }

    .wrapButton {
        display: flex;
        gap: 20px;

        & > * {
            min-width: 0;
            width: 0;
            flex: 1;
        }

        .cancel {
            //min-width: 150px;
            /*
            background: #EBEEF0;
            border-radius: 8px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            @include setProperty(color, --color-secondary);
            border: none;
            */
        }

        .okToRight {
            order: 1;
        }
    }
}

@import "@/styles/index.scss";

.buttonCatalogue {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 48px;
    @include mobileParameter(height, 40px);

    @include mediaParameter(min-width, 140px, 140px, unset, unset);
    @include mediaParameter(max-width, 180px, 180px, 48px, 40px);
    @include mediaParameterSm(width, 48px);
    @include mobileParameter(width, 40px);

    @include setProperty(color, --color-primary);
    border-width: 1px;
    border-style: solid;
    @include setProperty(border-color, --color-primary);
    @include setProperty(border-radius, --button-border-radius);
    box-sizing: border-box;

    transition: background-color 0.3s, border 0.3s;

    @include mediaParameter(padding, 12px 24px, 12px 16px, unset, unset);

    .icon {
        margin-right: 0.5rem;
        @include mediaParameterSm(margin-right, unset);

        height: 24px;
        width: 24px;
    }

    .name {
        white-space: nowrap;
        font-weight: 700;
        @include labelFontSize;
        @include mediaParameterSm(display, none);
        line-height: 24px;
        align-self: stretch;
    }

    &:hover {
        cursor: pointer;
        border: 1px solid #828B94;
        background-color: #E0F0F2;
    }
}

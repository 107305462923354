@import "@/styles/index.scss";

.CookiesConsent {
    position: fixed;
    bottom: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    gap: 12px;

    @include setProperty(background-color, --modal-background);
    @include setProperty(padding, --modal-padding);
    @include setProperty(border-radius, --modal-border-radius);
    @include setProperty(box-shadow, --modal-shadow);

    margin: 12px;
    @include mobileParameter(margin, 0);

    @include mobileParameter(width, 100vw);
    width: 540px;
    @include mobileParameter(min-width, 100vw);
    z-index: 10000;

    .title {
        @include fontSubtitleS1();
    }

    .text {
        @include fontBodyMediumRegular();
    }

    .buttonsWrapper {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        @include mobileParameter(flex-direction, column);
    }
}

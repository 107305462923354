@import "@/styles/index.scss";

.ButtonRich {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .wrapIcon {
    position: relative;
    width: 28px;

    .count {
      position: absolute;
      top: 0px;
      right: -5px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      @include setProperty(color, --white-background-color);
      font-weight: 500;
      @include setProperty(font-size, --font-size-sm);
      line-height: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .label {
    @include setProperty(font-size, --font-size-sm);
    font-weight: 700;
    letter-spacing: 0.005em;
    color: #828B94;

    &_active {
      @include setProperty(color, --black-background-color);
    }
  }
}

.green {
  @include setProperty(background-color, --color-success);
}

.red {
  @include setProperty(background-color, --color-error);
}

.primary {
  @include setProperty(background-color, --color-primary);
}

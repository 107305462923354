@import "@/styles/index.scss";

.Footer {
    $step_one: 1280px;
    $step_two: 768px;
    $step_tree: 550px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .wrapLogo {
        display: flex;
        flex-direction: column;

        .homeLink {
            display: flex;
            align-items: center;
            font-size: 1.75rem;
            filter: grayscale(1);
            margin-bottom: 16px;
            @include mobileParameter(margin-bottom, unsets);

            span {
                margin-left: 10px;
                letter-spacing: 0.15px;
                color: #191C1F;
            }
        }

        .logo {}

        @media screen and (max-width: $step_two) {
            align-items: center;
        }
    }

    .blockMax {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 32px;
        @include mobileParameter(padding, 24px 0 26px 0);

        @media screen and (max-width: $step_one) {
            gap: 70px;
        }

        @media screen and (max-width: $step_two) {
            display: unset;
            text-align: center;
            gap: unset;
            align-items: center;
        }
    }

    .block {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 36px;

        @media screen and (max-width: $step_one) {
            flex-direction: column;
            // margin-left: 52px;
            // gap: unset;
        }

        @media screen and (max-width: $step_two) {
            margin-left: 0;
            gap: unset;
        }
    }

    .wrapLinks {
        flex: 1;
        display: flex;
        justify-content: center;

        @media screen and (max-width: $step_one) {
            justify-content: unset;
        }

        @media screen and (max-width: $step_two) {
            justify-content: center;
        }
    }

    .links {
        display: flex;
        flex-wrap: wrap;
        @include mobileParameter(align-items, center);
        column-gap: 32px;
        @include mobileParameter(gap, 24px);
        // justify-content: center;
        @include labelFontSize;
        line-height: 2rem;

        a {
            // margin-right: 32px;
            white-space: nowrap;
        }

        @media screen and (max-width: $step_one) {
            justify-content: unset;
            // margin-bottom: 37px;
            // margin-left: 18px;
        }

        @media screen and (max-width: $step_two) {
            flex-direction: column;
            // margin-bottom: 32px;
            margin-left: 0;

            &>* {
                // margin: 12px 0;
            }

            a {
                margin-right: 0;
            }
        }
    }

    .logoIcon {
        filter: grayscale(1);
        margin-right: 10px;
    }

    .icons {
        display: flex;
        // filter: opacity(0.3);
        @include mobileParameter(margin, 48px 0 32px 0);
        gap: 32px;

        a {
            margin-right: 0;
        }

        svg {
            // margin: 3px 16px;
            cursor: pointer;
            width: 28px;
            height: 28px;

            &:hover {
                opacity: 1;
            }
        }

        .market, .social {
            // margin: 0 16px;
            width: 28px;
            height: 28px;
        }

        // .social {
        //     // margin: 0 16px;
        //     width: 24px;
        //     height: 24px;
        // }

        @media screen and (max-width: $step_two) {
            justify-content: center;
        }
    }

    .subscribeWrapper {
        //@include setProperty(background-color, --layout-footer-background);
        background-color: #f0f2f5;
        padding: 20px 0;


        .subscribeBlock {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: $step_tree) {
                flex-direction: column;
            }

            .subscribeLabel {
                display: flex;
                align-items: center;
                gap: 20px;

                font-size: 18px;
                font-weight: 700;
                @include setProperty(color, --color-black);
            }

            .formSubscribe {
                position: relative;
                width: 629px;
                @media screen and (max-width: $step_one) {
                    width: 481px;
                }
                @media screen and (max-width: $step_two) {
                    width: 353px;
                }
                @media screen and (max-width: $step_tree) {
                    width: 100%;
                }

                .subscribeInput {
                    border: none !important;
                    border-radius: 8px;

                    input {
                        height: 48px !important;
                        border-radius: 8px;
                        padding-right: 140px;
                        background-color: unset !important;
                        border: none !important;
                        outline: none !important;
                        width: 100% !important;
                    }
                }

                .subscribeBtn {
                    position: absolute;
                    right: 3px;
                    top: 2px;
                    max-height: 44px;
                    @media screen and (max-width: $step_two) {
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    @media screen and (max-width: $step_tree) {
                        padding-right: 12px;
                        padding-left: 12px;
                    }
                }
            }
        }
    }

    .footerMenu {
        padding-bottom: 24px;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        @media screen and (max-width: $step_tree) {
            flex-direction: column;
            padding-top: 0;
            padding-bottom: 0;
            gap: 0;
        }

        .menuBlock {
            flex-grow: 1;
            flex-basis: 0;

            .menuBlockTitle {
                width: 100%;
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                @include setProperty(color, --color-secondary);
                margin-bottom: 20px;
                @media screen and (max-width: $step_tree) {
                    height: 48px;
                    margin-bottom: 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .arrow {
                    display: none;
                    transition: transform 0.25s ease;
                    @media screen and (max-width: $step_tree) {
                        display: inline-block;

                        &.opened {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .menuBlockItems {
                display: flex;
                flex-direction: column;
                gap: 12px;

                @media screen and (max-width: $step_tree) {
                    overflow: hidden;
                    height: max-content;

                    transform: scaleY(1);
                    transform-origin: top;
                    transition: transform 0.25s ease;

                    &.closed {
                        height: 0;
                        transform: scaleY(0);
                        transition: transform 0.25s ease;
                        padding-bottom: 0;
                    }
                }

                .menuItem {
                    font-size: 15px;
                    line-height: 20px;
                    @include setProperty(color, --color-black);
                    @include hovered(true);
                }

                .menuItemTooltipWrapper {
                    width: max-content;
                }

                @media screen and (max-width: $step_tree) {
                    padding-bottom: 12px;
                }
            }

            .menuBlockSeparator {
                display: none;
                width: 100vw;
                min-height: 1px;
                background-color: #DBDEE0;
                margin-left: -12px;
                @media screen and (max-width: $step_tree) {
                    display: block;
                }
            }
        }
    }

    .footerSeparator {
        min-height: 1px;
        flex: 1;
        background-color: #DBDEE0;
        @media screen and (max-width: $step_tree) {
            display: none;
        }
    }

    .footerSocials {
        padding-bottom: 20px;
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        @media screen and (max-width: 1024px) {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        @media screen and (max-width: $step_tree) {
            align-items: center;
        }

        .socialBlock {
            //flex-grow: 1;
            display: flex;
            gap: 20px;
            align-items: center;
            min-height: 64px;
            @media screen and (max-width: 1024px) {
                min-height: unset;
            }
            @media screen and (max-width: $step_tree) {
                flex-direction: column;
                align-items: center;
            }
        }

        .infoLabel {
            font-size: 15px;
            font-weight: 700;
            @include setProperty(color, --color-secondary);
        }

        .footerSocialsIcons {
            display: flex;
            gap: 20px;
            align-items: center;

            & > a {
                @include hovered(false, true);
            }

            .market, .social {
                width: 28px;
                height: 28px;
            }
        }

        .logoBlock {
            min-height: 60px;
            //flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;

            a {
                width: 165px;
                height: 40px;
            }
        }

        .filler {
            flex: 1;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }

    .copyright {
        @include fontLabelSmall;
        @include setProperty(color, --color-secondary);
    }
}


@import "@/styles/index.scss";

.TopMenu {
    min-height: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div {
        //flex: 1;
        display: flex;
    }

    .anchor {
        display: flex;
        align-items: center;
        margin-left: 2rem;
        @include fontLabelSmall;
        @include hovered(true);
    }

    .name {
        margin-left: 6px;
    }

    &_logoText {
        font-size: 1.5rem !important;
        margin-right: 30px;
    }

}

@import "@/styles/index.scss";

.ButtonScrollToTop {
    position: fixed;
    bottom: 48px;
    @include mediaParameter(right, 24px, 16px, 16px, 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid;
    @include setProperty(border-color, --color-primary);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;

    svg {
        @include setProperty(color, --color-primary);
    }

    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;

    &.show {
       opacity: 1;
       pointer-events: all;
    }

    &.arrowDown {
        transform: rotate(180deg);
    }
}

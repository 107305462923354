@import "@/styles/index.scss";

.StickyMenu {
    position: sticky;
    z-index: 9999;
    top: 0;
    height: $stickyMenuHeight;
    //@include mobileParameter(height, 56px);
    //background-color: #f0f2f5;
    @include setProperty(background, --color-white);

    &.scrolled {
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    .wrap {
        display: flex;
        height: 100%;
        align-items: center;
        @include mediaParameter(gap, 28px, 20px, 12px, unset);
        //@include mobileParameter(padding, 0, true);
        //@include mobileParameter(width, unset, true);
    }

    @media screen and (max-width: $mobile) {
        display: flex;
        flex-direction: column;
        height: 120px;
        @include mobileParameter(height, 64px);
        @include mobileParameter(margin-bottom, 8px);

        .wrap {
            height: unset;
            padding: 12px;
        }
    }

    .left {
        display: flex;
        align-items: center;
    }

    .center {
        flex: 1;
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: right;
        gap: 28px;
        @include mobileParameter(gap, 18px);
        @include mobileParameter(margin-right, 11px);

        .icon {
            @media screen and (max-width: $mobile) {
                height: 28px;
                justify-content: unset;
            }

            &:hover {
                @include setProperty(opacity, --opacity-value);
            }
        }

        .iconLabel {
            @include mediaParameterSm(display, none);
            @include fontLabelSmall;
        }
    }
}

.wrapLogo {
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    text-transform: uppercase;
    @include mobileParameter(margin-left, 12px);

    .icon {
        @include mediaParameter(width, 204px, 204px, 204px, 102px);
        @include mediaParameter(height, 48px, 48px, 48px, 28px);
    }

    .name {
        margin-left: 12px;
        letter-spacing: 0.15px;
        color: #191C1F;
        @include mobileParameter(display, none);
    }
}

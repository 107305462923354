@import "@/styles/index.scss";

.avatar {
  .icon {
    width: 100%;
    height: 100%;
  }

  .image {
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
  }
}

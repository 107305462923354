@import "@/styles/index.scss";

.ButtonGlobalSearch {

    .back {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 100vh;
        background-color: #8080808c;

        opacity: 1;
        transition: opacity 0.3s, background-color 0.3s;

        &.hidden {
            pointer-events: none;
            opacity: 0;
        }
    }

    .mobileButton {
        display: none;
        @include mobileParameter(display, block);

        opacity: 1;
        transition: opacity 0.3s;

        &.hidden {
            opacity: 0;
        }
    }

    .wrapInput {
        position: relative;
        display: flex;
        align-items: center;
        @include setProperty(background, --background-color);
        @include mobileParameter(background, #afb3b7, true);
        border-radius: 8px;
        border: 1px solid #DBDEE0;
        @include mobileParameter(border, 0);
        height: 48px;
        @include mobileParameter(height, 64px, true);

        .iconBack {
            position: absolute;
            top: 0px;
            left: 22px;
            height: 100%;
            display: flex;
            align-items: center;
            @include setProperty(color, --color-primary);

            svg {
                transform: translateX(1px);
            }
        }

        &.mobile {
            z-index: 1;
            display: block;
            background: none;
            border-radius: unset;
            transform: translateY(0px);

            @media screen and (max-width: $mobile) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: #F0F2F5;
                padding: 12px;
            }

            input {
                @include mobileParameter(height, 40px, true);
                @include mobileParameter(padding-left, 40px, true);
                @include mobileParameter(padding-right, 40px, true);
                @include mobileParameter(margin, 0);
            }
        }

        opacity: 1;
        transition: opacity 0.3s, transform 0.3s;

        &.hidden {
            pointer-events: none;
            opacity: 0;
            transform: translateY(-64px);
        }
    }

    input {
        height: 46px;
        @include mobileParameter(height, 40px, true);
        width: 100%;
        @include mobileParameter(width, calc(100vw - 28px));
        box-sizing: border-box;
        @include setProperty(background, --background-color);
        border-radius: 8px;
        outline: 0;
        border: 0;
        padding: 0 !important;
        font-family: var(--font-inter);
        font-size: 1rem;
        letter-spacing: 0.25px;
        margin-right: 8px;
        margin-left: 16px;
    }

    input::placeholder {
        font-family: var(--font-inter);
        font-size: 1rem;
        letter-spacing: 0.25px;
        color: #828B94;
    }

    .icon {
        margin-left: 16px;
        margin-right: 8px;
        margin-top: 6px;

        &.mobile {
            position: absolute;
            margin: 0;
            @media screen and (max-width: $mobile) {
                left: 24px;
                top: 16px;
            }
        }
    }

    .clear {
        @include hovered();
        display: flex;
        align-items: center;
        filter: invert(0.6);
        margin-right: 8px;

        &.mobile {
            position: absolute;
            right: 60px;
            top: 0;
            bottom: 0;
            margin-right: 0;
        }
    }

    .button {
        background-color: #06768D !important;
        border-color: #06768D !important;
        @include mediaParameter(height, 44px, 48px, 48px, 48px);
        @include mediaParameter(width, auto, 64px, 48px, 48px);
        @include mediaParameter(min-width, auto, 64px, 48px, 48px);

        padding: 12px 32px;
        @include mediaParameterMd(padding, 0);

        margin-right: 2px;
        @include mediaParameterMd(margin-right, 0);

        font-weight: 700;
        @include labelFontSize;
        font-family: var(--font-primary);
    }

    .buttonNew {
        margin-right: 16px;

        &.mobile {
            position: absolute;
            top: 10px;
            right: 8px;
        }

        &.loading {
            opacity: 0;
            pointer-events: none;
        }
    }

    .iconNew {
        @include setProperty(fill, --color-primary);
    }

    .loader {
        margin-right: 14px;

        &.mobile {
            position: absolute;
            right: 27px;
            top: 21px;
            margin-right: 0;
        }
    }

    .buttonText {
        @include mediaParameterMd(display, none);
    }

    .buttonIcon {
        display: none;
        @include mediaParameterMd(display, block);

        &.loading {
            display: none;
        }
    }

    .buttonLoader.loading {
        @include mediaParameterMd(margin, 0);
    }
}

.contentBlur {
    filter: blur(5px) opacity(0.7);
    pointer-events: none;
}

@import "@/styles/index.scss";

.Toastify {
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.15px;
}

.error, .info, .success, .warning {
  white-space: break-spaces;
  word-break: break-word;
}

.info {
  background-color: #0D7D8B !important;
  @include setProperty(background-color, --toast-background-color-info);
  color: white !important;
  button {
    opacity: 1;
  }
  svg, path {
    fill: white !important;
  }
}

.success {
  background-color: #0D7D8B !important;
  @include setProperty(background-color, --toast-background-color-success);
  color: white !important;
}

.warning {
  background-color: rgb(248, 101, 92) !important;
  @include setProperty(background-color, --toast-background-color-warning);
  color: white !important;
}

.error {
  background-color: rgb(248, 101, 92) !important;
  @include setProperty(background-color, --toast-background-color-error);
  color: white !important;
}

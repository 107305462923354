@import "@/styles/index.scss";

.Modal {
    position: absolute;
    height: fit-content;
    max-height: 100%;
    overflow: auto;
    overflow-x: hidden;

    pointer-events: none;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.15s, transform 0.3s;

    @include setProperty(background-color, --modal-background);
    @include setProperty(padding, --modal-padding);
    &.smallPadding {
        @include setProperty(padding, --modal-small-padding);
    }
    @include setProperty(border-radius, --modal-border-radius);
    @include setProperty(box-shadow, --modal-shadow);
    @include mobileParameter(width, 100vw);
    min-width: 300px;
    @include mobileParameter(min-width, 100vw);

    @include scrollable();

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-moz-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
    }

    @media screen and (max-width: $mobile) {
        @include scrollable(0px);
    }

    &.show {
        pointer-events: unset;
        opacity: 1;
        transform: translateY(0%);
    }

    &.showAsParent {
        pointer-events: none;
        position: absolute;
        opacity: 1;
        transform: translateY(0%);
        filter: blur(2px) grayscale(1) brightness(0.8);
    }
}

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    @include mobileParameter(align-items, unset);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00000080;
    z-index: 100000;
}

.wrapContent {
    top: 0;
    bottom: 0;
    position: relative;
    z-index: 100001;
    display: flex;
    align-items: center;
    @include mobileParameter(align-items, flex-end);
    justify-content: center;
    transition: top 0.3s, min-height 0.3s;
}

.header {
    display: flex;
    align-items: center;

    svg {
        margin-right: 12px;

        &.primary {
            @include setProperty(color, --color-primary);
        }

        &.secondary {
            @include setProperty(color, --color-secondary);
        }

        &.info {
            @include setProperty(color, --color-info);
        }

        &.error {
            @include setProperty(color, --color-error);
        }
    }

    .iconClose {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 1;

        &.denseIconClose {
            top: 12px;
            right: 12px;
        }
    }

    .iconBack {
        position: absolute;
        display: flex;
        align-items: center;
        top: 20px;
        left: 20px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        z-index: 1;
    }
}

.title {
    font-family: var(--font-inter);
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.15px;
    width: 100%;
}

@import "@/styles/index.scss";

.TooltipWrapper {
    position: relative;

    .Tooltip {
        position: absolute;
        max-width: 200px;
        width: max-content;
        pointer-events: none;
        z-index: 100;

        left: 50%;

        @include setProperty(background, --color-black);
        border-radius: 4px;
        padding: 8px 12px;
        color: #FFFFFF;
        @include fontBodySmallRegular;
        text-align: left;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
        }

        &.bottom {
            bottom: -6px;
            transform: translate(-50%, 100%);

            &:before {
                top: -11px;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid #191C1F;
            }
        }

        &.top {
            top: -6px;
            transform: translate(-50%, -100%);

            &:before {
                bottom: -11px;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 12px solid #191C1F;
            }
        }
    }
}

@import "@/styles/index.scss";

.SelectContact {
    .label {
        @include fontLabelSmall;
        @include setProperty(color, --color-secondary);
    }
}

.SelectBackdrop {
    border-radius: 8px;
    @include setProperty(box-shadow, --popup-catalogue-shadow, true);
    max-height: max-content !important;
}

.ContactsWrapper {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 12px !important;

    .contact {
        display: flex;
        gap: 4px;
        align-items: center;
        @include setProperty(color, --color-black);
        @include fontBodyMediumRegular();
        line-height: 15px;
        &:hover {
            text-decoration: underline;
        }
        span {
            padding-top: 3px;
        }
        &.link {
        }
        &.phoneNumber {
            cursor: copy;
        }
        &.macOSPaddings {
            span {
                padding-top: 0;
            }
        }
    }

    .workTimeWrapper {
        @include fontBodySmallRegular;
        @include setProperty(color, --color-secondary);
        cursor: default;
    }
}



@use "sass:meta";
@use "sass:string";

$xs: 375px;
$xsm: 540px;
$sm: 768px;
$ms: 960x;
$md: 1024px;
$lg: 1440px;
$xl: 1920px;

$mobile: 768px;
$smallMobile: 370px;
$maxWidthContent: 1320px;

$stickyMenuHeight: 76px;

@import "@/styles/index.scss";

.Preloader {
    height: 4px;
    @include mobileParameter(height, 2px);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 9999px;
    overflow: hidden;

    .progress {
        @include setProperty(background-color, --color-primary);
        border-radius: 9999px;
        position: absolute;
        bottom: 0;
        top: 0;
        width: 50%;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: indeterminate-progress-bar;
    }

    @keyframes indeterminate-progress-bar {
        from {
            left: -50%;
        }

        to {
            left: 100%;
        }
    }
}
@import "@/styles/index.scss";

.SelectCurrency {
  margin-left: 32px;

  .value {
    display: flex;
    text-transform: uppercase;
    @include fontLabelSmall;
    @include setProperty(color, --color-black);
  }
}
